import observe from '../utils/observe';
import sequence from '../utils/sequence';
import { doLog } from '../actions/log';


const RESPONSIVENESS = 'responsiveness';

let isInfoCalled = false;
const {report, result} = sequence(window, RESPONSIVENESS, RESPONSIVENESS);
window.addEventListener('info-called', () => isInfoCalled = true);

/**
 * PerformanceObserver callback for responsiveness (type "event")
 * @param {PerformanceEntryList} entries
 * @returns {void}
 */
export const measureResponsiveness = (isLogging) => {
    const interactionMap = {};

    let numOfResponsivenessEvents = 0;
    let worstLatency = 0;
    let worstLatencyOverBudget = 0;
    let totalLatencyOverBudget = 0;

    return (entries) => {
        for (const entry of entries) {
            // Ignore entries without an interaction ID.
            if (entry.interactionId > 0) {
            // Get the interaction for this entry, or create one if it doesn't exist.
                let interaction = interactionMap[entry.interactionId];
                if (!interaction) {
                    interaction = {latency: 0, entries: []};
                    interactionMap[entry.interactionId] = interaction;
                }
                interaction.entries.push(entry);

                const latency = Math.max(entry.duration, interaction.latency);
                worstLatency = Math.max(worstLatency, latency);

                const budget = entry.name.includes('key') ? 50 : 100;
                const latencyOverBudget = Math.max(latency - budget, 0);
                worstLatencyOverBudget = Math.max(latencyOverBudget, worstLatencyOverBudget);

                if (latencyOverBudget) {
                    const oldLatencyOverBudget = Math.max(interaction.latency - budget, 0);
                    totalLatencyOverBudget += latencyOverBudget - oldLatencyOverBudget;
                }
            
                numOfResponsivenessEvents++;
                interaction.latency = latency;
                const currentResponsivenessMeasure = {
                    entryType: RESPONSIVENESS,
                    worstLatency,
                    worstLatencyOverBudget,
                    totalLatencyOverBudget,
                    numOfResponsivenessEvents,
                };

                if (latencyOverBudget === worstLatencyOverBudget) {
                    report(currentResponsivenessMeasure);
                }

            
                if (isLogging || isInfoCalled) {
                    doLog({
                        ...currentResponsivenessMeasure, 
                        element: entry.target,
                        eventName: entry.name,
                    });
                }
            }
        }
    };
};

/**
 * @param {import('./utils/utils.js').State} state 
 * @param {boolean} isLogging
 */
export const startMeasureResponsiveness = (isLoggingEnabled) => {
    observe(window.PerformanceObserver, 'event', measureResponsiveness(isLoggingEnabled), true, { durationThreshold: 50 });
    
    return result;
};